import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const ContactUs = () => {
    const form = useRef();
  
    const sendEmail = (e) => {
    e.preventDefault();

    emailjs
        .sendForm('service_uf3b0hn', 'template_df4y8io', form.current, {
        publicKey: 'cYOk_F6aZQ-1bTNGc',
        })
        .then(
        () => {
            console.log('Enviado');
        },
        (error) => {
            console.log('Error', error.text);
        },
        );
        e.target.reset()
    };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Te Contactamos.</h2>
                <form ref = {form} onSubmit={sendEmail}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" placeholder="Nombre" name="to_name" />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" placeholder="Apellido" name="to_last"/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" placeholder="Correo electronico" name="from_email"/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" placeholder="Celular" name="from_number"/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" placeholder="Mensaje" name="message"></textarea>
                      <button type="submit" value="send">Enviar</button>
                    </Col>
                 
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
