import respect from "../assets/img/respect.png";
import seguridad from "../assets/img/seguridad.png";
import shield1 from "../assets/img/shield1.png";
import shield2 from "../assets/img/shield2.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Quienes somos</h2>
                        <p>Equatel CORP S.A.S es una empresa, que brinda servicio de Internet a través de redes de fibra óptica, <br></br> logrando que los habitantes de zonas rurales y urbanas tengan una conexión digital hacia el mundo. <br></br>Nos caracterizamos por brindar las siguientes cualidades:</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={seguridad} alt="Image"/>
                                <h5>Seguridad</h5>
                            </div>
                            <div className="item">
                                <img src={shield1} alt="Image"/>
                                <h5>Responsabilidad</h5>
                            </div>
                            <div className="item">
                                <img src={shield2} alt="Image" />
                                <h5>Respeto</h5>
                            </div>
                            <div className="item">
                                <img src={respect} alt="Image" />
                                <h5>Atencion</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
