import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, button, imgUrl }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <a href="https://wa.me/message/2HD4DADEHE7GD1" class="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" ><h5>{button}</h5></a>
        </div>
      </div>
    </Col>
  )
}
