import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import medidor from "../assets/img/medidorMegas.jpg";
import TrackVisibility from 'react-on-screen';
import Image from 'react-bootstrap/Image'
import Iframe from "react-iframe";

export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    email.indexOf("") > -1 &&
    onValidated({
      EMAIL: email
    })
  }

  const clearFields = () => {
    setEmail('');
  }

  return (    
    <Col lg={12}>
        <div className="newsletter-bx wow slideInUp">
          <Row>
            <Col lg={12} md={6} xl={8}>
            <Iframe  src={medidor} width="1000" height="400"/>
            <p>PRTG Network Monitor 13.2.3.2235+ , Network Monitoring Software © 2013 Paessler AG,</p>
            </Col>
          </Row>
        </div>
      </Col>
    /* <!-- Form End --> 
  
  <div className="newsletter-bx wow slideInUp">
      <Row>
          <div>
            <Iframe  src={medidor} width="1300" height="400"/>
          </div>
        <p></p>             
      </Row>
    </div>   */
    
  )
}

