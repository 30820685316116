import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/EQUATEL_LOGO.png";
import navIcon1 from "../assets/img/speedometer.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.speedtest.net/" ><img src={navIcon1} alt="" /></a>
              <a href="https://www.facebook.com/"><img src={navIcon2} alt="" /></a>
              <a href="https://www.instagram.com/?hl=en"><img src={navIcon3} alt="" /></a>
            </div>
            
            <div>
              <a href="https://www.arcotel.gob.ec/"  class="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><p>Arcotel</p></a>
              <a href="https://gonet.ec/wp-content/uploads/2021/08/GUIA-CONTROL-PARENTAL-_-ARCOTEL.pdf"  class="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><p>Guia de control parental</p></a>
              <a href="https://gonet.ec/wp-content/uploads/2022/05/RESOLUCION-ARCOTEL-2022-0123.pdf"  class="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><p>Resolución ARCOTEL 2022 - 0123</p></a>
            </div>
            <p>Copyright 2024. EQUATEL CORP S.A.S. Diseñado por APlay</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
