import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import Iframe from "react-iframe";
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import projImg1 from "../assets/img/Plan Basico/1.png";
import projImg2 from "../assets/img/Plan Basico/2.png";
import projImg3 from "../assets/img/Plan Basico/3.png";
import projImg4 from "../assets/img/Plan Basico/4.png";
import projImg5 from "../assets/img/Plan Basico/5.png";
import projImg6 from "../assets/img/Plan Basico/6.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Plan 100 mbps",
      description: "",
      button: "Solicitar plan",
      imgUrl: projImg1,
    },
    {
      title: "Plan 150 mbps",
      ddescription: "",
      button: "Solicitar plan",
      imgUrl: projImg2,
    },
    {
      title: "Plan 200 mbps",
      description: "",
      button: "Solicitar plan",
      imgUrl: projImg3,
    },
    {
      title: "Plan 250 mbps",
      description: "",
      button: "Solicitar plan",
      imgUrl: projImg4,
    },
    {
      title: "Plan 300 mbps",
      description: "",
      button: "Solicitar plan",
      imgUrl: projImg5,
    },
    {
      title: "Plan 350 mbps",
      description: "",
      button: "Solicitar plan",
      imgUrl: projImg6,
    },
  ];

  const position = [-2.309123, -78.119251]

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Plan de Internet</h2>
                <p class="text-justify">Disfruta de nuestros planes de internet con los mejores precios. Cada plan tiene 100% de descuento en la instalación.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Plan</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Medidor de Velocidad</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Cobertura</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Iframe  url="https://fast.com/es/#"
                        width="1300px"
                        height="720px"
                        id=""
                        className=""
                        display="block"
                        position="center"/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div>
                        <p>Estamos ubicados en:</p>
                      </div>
                      <Container>
                        <Row>
                          <Col>
                          <div>
                          <p>Macas</p>
                          <Iframe  src="https://www.google.com/maps/d/embed?mid=11SYO2z9VdFcoEAvXEfDTgm2Jlz9PKwk&ehbc=2E312F" width="1300" height="480"/>
                          </div>
                          </Col>
                          {/* 
                          <Col Col lg={12} md={7} xl={6}>
                          <div>
                          <p>Sucua</p>
                          <Iframe src="https://www.google.com/maps/d/embed?mid=1CPL0GzC0oj2sBL23awCjQbBYx1-T7bU&ehbc=2E312F" width="640" height="480"></Iframe>
                          </div>
                          </Col>
                          */}
                          
                        </Row>
                      </Container>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
